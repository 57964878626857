import React from "react"
import { Link } from "gatsby"
import styles from "./footer.module.css"

const Footer = ({ type }) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h3>&#169; 2021 FinSimpl</h3>

        <h3 className={styles.contact}>
          <Link to="/contact-us/">Contact Us</Link>
        </h3>
      </div>
      {type === 1 ? (
        <>
          <div className={styles.privacy_policy}>
            FinSimpl is not responsible for third-party products, services,
            sites, recommendations, endorsements, reviews, etc. All products,
            logos, and company names are trademarks™ or registered® trademarks
            of their respective holders. Their use does not signify or suggest
            the endorsement, affiliation, or sponsorship, of or by FinSimpl or
            them of us. FinSimpl strives to provide a wide array of offers for
            our users, but our offers do not represent all financial services
            companies or products.
            <br />
            <br />
            We endeavour to ensure that the information on this site is current
            and accurate but you should confirm any information with the product
            or service provider and read the information they can provide.
            <br />
            <br />
            <p className={styles.fineprint}>
              By signing up, you agree to Finsimpl’s{" "}
              <Link to="/legal/" className={styles.as}>
                Terms of Use and Privacy Policy
              </Link>
            </p>
          </div>
        </>
      ) : type === 2 ? (
        <>
          <p className={styles.privacy_policy}>
            All loan information is presented without warranty, and estimated
            APR and other terms are not binding. FinSimpl’s lending partners
            generally present a range of APRs (for instance, from 5% to 35.99%)
            with a range of terms and monthly payments. As an example, a $10,000
            loan with an APR of 14.50% and a term of 36 months would have a
            monthly payment of $344.21. Actual APRs will depend on factors like
            credit score, loan amount, loan term, and credit history. Only
            borrowers with excellent credit will qualify for the lowest APRs.
            All loans are subject to credit review and approval. The origination
            fees are dependent on FinSimpl's lending partners and is subject to
            approval. For example, a $10,000 loan with a 1% origination fee will
            have $100 deducted from the loan receivable.
            <br />
            <br />
            <span className={styles.fineprint}>
              By signing up, you agree to Finsimpl’s{" "}
              <Link to="/legal/" className={styles.as}>
                Terms of Use and Privacy Policy
              </Link>
            </span>
          </p>
        </>
      ) : null}
    </div>
  )
}

export default Footer
