import React from "react"
import { Link } from "gatsby"

import styles from "./menu.module.css"
import logo from "../images/logo.png"
import { AnchorLink } from "gatsby-plugin-anchor-links"

import { SignOut } from "aws-amplify-react"

const Menu = ({ type, btype, cDetails = [] }) => {
  return (
    <nav className={styles.menu}>
      <div className={styles.menu_body}>
        <div className={styles.cola}>
          {cDetails[0] === undefined ? (
            <>
              <div className={styles.logo}>
                <Link to="/">
                  <img
                    src={logo}
                    className={styles.logo}
                    height="50px"
                    width="166.25px"
                    alt="FinSimpl Logo"
                  />
                </Link>
              </div>
            </>
          ) : (
            <h1 className={styles.cName}>{cDetails[1]}</h1>
          )}
        </div>
        <div className={styles.menu_headers}>
          {type === 1 ? (
            <>
              <AnchorLink to="/#home">
                <div className={styles.home}>Home</div>
              </AnchorLink>
              <AnchorLink to="/#howItWorks">
                <div className={styles.how_it_works}>How It Works</div>
              </AnchorLink>
              <AnchorLink to="/#pricing">
                <div className={styles.how_it_works}>Pricing</div>
              </AnchorLink>
              <AnchorLink to="/#faq">
                <div className={styles.faq}>FAQ</div>
              </AnchorLink>
            </>
          ) : null}
        </div>
        <div className={styles.colc}>
          {btype === 1 ? (
            <>
              <div className={styles.login}>
                <Link to="/login/" state={{ checkAuthStatus: true }}>
                  Log In
                </Link>
              </div>
              <div className={styles.button}>
                <Link to="/login/" state={{ checkAuthStatus: true }}>
                  <div className={styles.button_text}>Get Started</div>
                </Link>
              </div>
            </>
          ) : btype === 2 ? (
            <SignOut />
          ) : btype === 3 ? (
            <></>
          ) : (
            <div className={styles.button}>
              <Link to="/clientFormPage/">
                <div className={styles.button_text}>Get Started</div>
              </Link>
            </div>
          )}

          {cDetails[0] === undefined ? (
            <></>
          ) : (
            <div>
              <h1 className={styles.power}>Powered By: </h1>
              <div>
                <img
                  src={logo}
                  className={styles.logo}
                  height="50px"
                  width="166.25px"
                  alt="FinSimpl Logo"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </nav>
  )
}

export default Menu
